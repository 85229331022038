var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isSetupWizard
        ? _c("GeneralSubHeader", {
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v(" Grading Setup ")]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return undefined
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1663440687
            ),
          })
        : _c("GeneralSubHeader", {
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.gradeTemplate.gradeTemplateName) + " "
                    ),
                    _c("small", { staticClass: "ml-3" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.isNew ? "New" : "Edit") +
                          " Grade Template "
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "buttons",
                fn: function () {
                  return [
                    _c(
                      "button",
                      {
                        class: `btn btn-upper btn-primary ${
                          _vm.saving
                            ? "kt-spinner kt-spinner--sm kt-spinner--light kt-spinner--left"
                            : ""
                        }`,
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.save.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" Save Template ")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "kt-portlet" }, [
                _vm.isSetupWizard
                  ? _c("div", { staticClass: "kt-portlet__head" }, [
                      _c("div", { staticClass: "kt-portlet__head-label" }, [
                        !_vm.isAdminLocked && !_vm.finalized
                          ? _c("div", { staticStyle: { width: "72px" } }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "mt-3 kt-switch kt-switch--outline kt-switch--icon",
                                  class: _vm.enableGrading
                                    ? "kt-switch--success"
                                    : "kt-switch--danger",
                                },
                                [
                                  _c("label", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.enableGrading,
                                          expression: "enableGrading",
                                        },
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.enableGrading
                                        )
                                          ? _vm._i(_vm.enableGrading, null) > -1
                                          : _vm.enableGrading,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.enableGrading,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.enableGrading = $$a.concat(
                                                  [$$v]
                                                ))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.enableGrading = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.enableGrading = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c("span"),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c("h3", { staticClass: "kt-portlet__head-title" }, [
                          _vm._v(" Default Grade Template "),
                          _c("small", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.enableGrading ? "Enabled" : "Disabled"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                        _vm.enableGrading
                          ? _c(
                              "button",
                              {
                                class: `btn btn-primary ${
                                  _vm.saving
                                    ? "kt-spinner kt-spinner--sm kt-spinner--light kt-spinner--left"
                                    : ""
                                }`,
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.save.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(" Save Default School Template ")]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "form",
                  { staticClass: "kt-form kt-form--label-right p-4" },
                  [
                    _c(
                      "b-overlay",
                      {
                        attrs: {
                          variant: "transparent",
                          center: "",
                          opacity: "1",
                          blur: "10px",
                          spinner: "bubbles",
                          show: !_vm.enableGrading,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "overlay",
                            fn: function () {
                              return [_c("p")]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "kt-portlet__body" }, [
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-8 col-md-6 col-lg-8" },
                              [
                                _c("label", [_vm._v("Template Name")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.gradeTemplate.gradeTemplateName,
                                      expression:
                                        "gradeTemplate.gradeTemplateName",
                                    },
                                  ],
                                  staticClass:
                                    "form-control form-control-solid",
                                  attrs: {
                                    type: "text",
                                    placeholder: "e.g. Math Template",
                                  },
                                  domProps: {
                                    value: _vm.gradeTemplate.gradeTemplateName,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.gradeTemplate,
                                        "gradeTemplateName",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-4 col-md-6 col-lg-4" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "averagingMethod" } },
                                  [_vm._v("Averaging Method")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "kt-radio-inline mt-2" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "kt-radio kt-radio--brand",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.gradeTemplate.averageMethod,
                                              expression:
                                                "gradeTemplate.averageMethod",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            value: "Points",
                                            name: "averagingMethod",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.gradeTemplate.averageMethod,
                                              "Points"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.gradeTemplate,
                                                "averageMethod",
                                                "Points"
                                              )
                                            },
                                          },
                                        }),
                                        _c("span"),
                                        _vm._v(" Points "),
                                      ]
                                    ),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "kt-radio kt-radio--brand",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.gradeTemplate.averageMethod,
                                              expression:
                                                "gradeTemplate.averageMethod",
                                            },
                                          ],
                                          attrs: {
                                            type: "radio",
                                            value: "Percentages",
                                            name: "averagingMethod",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.gradeTemplate.averageMethod,
                                              "Percentages"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.$set(
                                                _vm.gradeTemplate,
                                                "averageMethod",
                                                "Percentages"
                                              )
                                            },
                                          },
                                        }),
                                        _c("span"),
                                        _vm._v(" Percentages "),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "form-group row" }, [
                            _c("div", { staticClass: "col-sm-4" }, [
                              _c("div", { staticClass: "pr-4" }, [
                                _c("label", { attrs: { for: "maxPoints" } }, [
                                  _vm._v("Restrict Max Points"),
                                ]),
                                _c("div", { staticClass: "input-group" }, [
                                  _c(
                                    "div",
                                    { staticClass: "input-group-prepend" },
                                    [
                                      _vm.maxPointsRestricted
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "input-group-text",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  _vm.gradeTemplate.maxPoints =
                                                    null
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "kt-checkbox kt-checkbox--single kt-checkbox--danger",
                                                },
                                                [
                                                  _c("input", {
                                                    attrs: {
                                                      type: "checkbox",
                                                      checked: "checked",
                                                    },
                                                  }),
                                                  _c("span"),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "input-group-text" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "kt-checkbox kt-checkbox--single kt-checkbox--disabled",
                                                },
                                                [
                                                  _c("input", {
                                                    attrs: {
                                                      type: "checkbox",
                                                      disabled: "",
                                                    },
                                                  }),
                                                  _c("span"),
                                                ]
                                              ),
                                            ]
                                          ),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.gradeTemplate.maxPoints,
                                        expression: "gradeTemplate.maxPoints",
                                      },
                                    ],
                                    staticClass:
                                      "form-control form-control-solid",
                                    attrs: {
                                      id: "maxPoints",
                                      type: "number",
                                      min: "0",
                                      placeholder: "Not Restricted",
                                    },
                                    domProps: {
                                      value: _vm.gradeTemplate.maxPoints,
                                    },
                                    on: {
                                      focusout: _vm.afterMaxPointsUpdated,
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.gradeTemplate,
                                            "maxPoints",
                                            $event.target.value
                                          )
                                        },
                                        _vm.numberChanged,
                                      ],
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _c("div", { staticClass: "pr-4" }, [
                                _c("label", { attrs: { for: "maxWeight" } }, [
                                  _vm._v("Restrict Weight"),
                                ]),
                                _c("div", { staticClass: "input-group" }, [
                                  _c(
                                    "div",
                                    { staticClass: "input-group-prepend" },
                                    [
                                      _vm.maxWeightRestricted
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "input-group-text",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  _vm.gradeTemplate.maxWeight =
                                                    null
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "kt-checkbox kt-checkbox--single kt-checkbox--danger",
                                                },
                                                [
                                                  _c("input", {
                                                    attrs: {
                                                      type: "checkbox",
                                                      checked: "checked",
                                                    },
                                                  }),
                                                  _c("span"),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "input-group-text" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "kt-checkbox kt-checkbox--single kt-checkbox--disabled",
                                                },
                                                [
                                                  _c("input", {
                                                    attrs: {
                                                      type: "checkbox",
                                                      disabled: "",
                                                    },
                                                  }),
                                                  _c("span"),
                                                ]
                                              ),
                                            ]
                                          ),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.gradeTemplate.maxWeight,
                                        expression: "gradeTemplate.maxWeight",
                                      },
                                    ],
                                    staticClass:
                                      "form-control form-control-solid",
                                    attrs: {
                                      id: "maxWeight",
                                      type: "number",
                                      placeholder: "Not Restricted",
                                      min: "0",
                                    },
                                    domProps: {
                                      value: _vm.gradeTemplate.maxWeight,
                                    },
                                    on: {
                                      focusout: _vm.afterMaxWeightUpdated,
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.gradeTemplate,
                                            "maxWeight",
                                            $event.target.value
                                          )
                                        },
                                        _vm.numberChanged,
                                      ],
                                    },
                                  }),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-sm-4" }, [
                              _c("div", { staticClass: "pr-4" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "floorAverages" } },
                                  [_vm._v("Floor Averages")]
                                ),
                                _c("div", { staticClass: "input-group" }, [
                                  _c(
                                    "div",
                                    { staticClass: "input-group-prepend" },
                                    [
                                      _vm.floorAveragesRestricted
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "input-group-text",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  _vm.gradeTemplate.floorAverages =
                                                    null
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "kt-checkbox kt-checkbox--single kt-checkbox--danger",
                                                },
                                                [
                                                  _c("input", {
                                                    attrs: {
                                                      type: "checkbox",
                                                      checked: "checked",
                                                    },
                                                  }),
                                                  _c("span"),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "input-group-text" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "kt-checkbox kt-checkbox--single kt-checkbox--disabled",
                                                },
                                                [
                                                  _c("input", {
                                                    attrs: {
                                                      type: "checkbox",
                                                      disabled: "",
                                                    },
                                                  }),
                                                  _c("span"),
                                                ]
                                              ),
                                            ]
                                          ),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.gradeTemplate.floorAverages,
                                        expression:
                                          "gradeTemplate.floorAverages",
                                      },
                                    ],
                                    staticClass:
                                      "form-control form-control-solid",
                                    attrs: {
                                      id: "floorAverages",
                                      type: "number",
                                      placeholder: "Not Restricted",
                                      min: "0",
                                    },
                                    domProps: {
                                      value: _vm.gradeTemplate.floorAverages,
                                    },
                                    on: {
                                      focusout: _vm.afterFloorAveragesUpdated,
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.gradeTemplate,
                                            "floorAverages",
                                            $event.target.value
                                          )
                                        },
                                        _vm.numberChanged,
                                      ],
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "input-group-append" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "input-group-text" },
                                        [_vm._v("%")]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "form-group row" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("label", {}, [
                                _vm._v("In School Scale Display Preferences:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "kt-radio-list ml-3 mt-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "kt-radio kt-radio--solid" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.gradeTemplate
                                                .schoolScaledDisplayPreference,
                                            expression:
                                              "gradeTemplate.schoolScaledDisplayPreference",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "school-display",
                                          value: "ShowPercentage",
                                        },
                                        domProps: {
                                          checked:
                                            _vm.gradeTemplate
                                              .schoolScaledDisplayPreference ==
                                            "ShowPercentage",
                                          checked: _vm._q(
                                            _vm.gradeTemplate
                                              .schoolScaledDisplayPreference,
                                            "ShowPercentage"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.gradeTemplate,
                                              "schoolScaledDisplayPreference",
                                              "ShowPercentage"
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" Category Raw Percentage "),
                                      _c("span"),
                                    ]
                                  ),
                                  _c(
                                    "label",
                                    { staticClass: "kt-radio kt-radio--solid" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.gradeTemplate
                                                .schoolScaledDisplayPreference,
                                            expression:
                                              "gradeTemplate.schoolScaledDisplayPreference",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "school-display",
                                          value: "ShowScale",
                                        },
                                        domProps: {
                                          checked:
                                            _vm.gradeTemplate
                                              .schoolScaledDisplayPreference ==
                                            "ShowScale",
                                          checked: _vm._q(
                                            _vm.gradeTemplate
                                              .schoolScaledDisplayPreference,
                                            "ShowScale"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.gradeTemplate,
                                              "schoolScaledDisplayPreference",
                                              "ShowScale"
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" Show Grade Scale "),
                                      _c("span"),
                                    ]
                                  ),
                                  _c(
                                    "label",
                                    { staticClass: "kt-radio kt-radio--solid" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.gradeTemplate
                                                .schoolScaledDisplayPreference,
                                            expression:
                                              "gradeTemplate.schoolScaledDisplayPreference",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "school-display",
                                          value: "All",
                                        },
                                        domProps: {
                                          checked:
                                            _vm.gradeTemplate
                                              .schoolScaledDisplayPreference ==
                                            "All",
                                          checked: _vm._q(
                                            _vm.gradeTemplate
                                              .schoolScaledDisplayPreference,
                                            "All"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.gradeTemplate,
                                              "schoolScaledDisplayPreference",
                                              "All"
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" Show All "),
                                      _c("span"),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("label", {}, [
                                _vm._v(
                                  "Guardian/Student Scale Display Preferences:"
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "kt-radio-list ml-3 mt-2" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "kt-radio kt-radio--solid" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.gradeTemplate
                                                .homeScaledDisplayPreference,
                                            expression:
                                              "gradeTemplate.homeScaledDisplayPreference",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "home-display",
                                          value: "ShowPercentage",
                                        },
                                        domProps: {
                                          checked:
                                            _vm.gradeTemplate
                                              .homeScaledDisplayPreference ==
                                            "ShowPercentage",
                                          checked: _vm._q(
                                            _vm.gradeTemplate
                                              .homeScaledDisplayPreference,
                                            "ShowPercentage"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.gradeTemplate,
                                              "homeScaledDisplayPreference",
                                              "ShowPercentage"
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" Category Raw Percentage "),
                                      _c("span"),
                                    ]
                                  ),
                                  _c(
                                    "label",
                                    { staticClass: "kt-radio kt-radio--solid" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.gradeTemplate
                                                .homeScaledDisplayPreference,
                                            expression:
                                              "gradeTemplate.homeScaledDisplayPreference",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "home-display",
                                          value: "ShowScale",
                                        },
                                        domProps: {
                                          checked:
                                            _vm.gradeTemplate
                                              .homeScaledDisplayPreference ==
                                            "ShowScale",
                                          checked: _vm._q(
                                            _vm.gradeTemplate
                                              .homeScaledDisplayPreference,
                                            "ShowScale"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.gradeTemplate,
                                              "homeScaledDisplayPreference",
                                              "ShowScale"
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" Show Grade Scale "),
                                      _c("span"),
                                    ]
                                  ),
                                  _c(
                                    "label",
                                    { staticClass: "kt-radio kt-radio--solid" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.gradeTemplate
                                                .homeScaledDisplayPreference,
                                            expression:
                                              "gradeTemplate.homeScaledDisplayPreference",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          name: "home-display",
                                          value: "All",
                                        },
                                        domProps: {
                                          checked:
                                            _vm.gradeTemplate
                                              .homeScaledDisplayPreference ==
                                            "All",
                                          checked: _vm._q(
                                            _vm.gradeTemplate
                                              .homeScaledDisplayPreference,
                                            "All"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.gradeTemplate,
                                              "homeScaledDisplayPreference",
                                              "All"
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" Show All "),
                                      _c("span"),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c(
                            "ul",
                            {
                              staticClass:
                                "nav nav-tabs nav-tabs-line nav-tabs-line-brand",
                              attrs: { role: "tablist" },
                            },
                            _vm._l(_vm.tabs, function (tab, idx) {
                              return _c(
                                "li",
                                { key: `tab_${idx}`, staticClass: "nav-item" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      class: { active: tab.active },
                                      attrs: {
                                        "data-toggle": "tab",
                                        href: "#",
                                        role: "tab",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.selectTabByIndex(idx)
                                        },
                                      },
                                    },
                                    [
                                      _c("SVGIcon", {
                                        attrs: { name: tab.svgName },
                                      }),
                                      _vm._v(" " + _vm._s(tab.tabName) + " "),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _c("div", { staticClass: "row" }, [
                            _vm.selectedTab.tabName == "Grade Categories"
                              ? _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-success btn-sm mt-4 pull-right",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.addGradeCategory.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Add New Category ")]
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "table-responsive mb-0" },
                                    [
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "table table-hover table-borderless",
                                          attrs: { id: "gradingCategories" },
                                        },
                                        [
                                          _c(
                                            "thead",
                                            {
                                              staticClass:
                                                "kt-datatable__head kt-thead__light",
                                            },
                                            [
                                              _c(
                                                "tr",
                                                {
                                                  staticClass:
                                                    "kt-datatable__row",
                                                },
                                                [
                                                  _c("th", [
                                                    _c("span", [
                                                      _vm._v("Category"),
                                                    ]),
                                                  ]),
                                                  _c("th", [
                                                    _c("span", [
                                                      _vm._v("Worth"),
                                                    ]),
                                                  ]),
                                                  _c("th", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Drop Lowest Grade"
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.gradeTemplate.categories,
                                              function (category, i) {
                                                return _c(
                                                  "tr",
                                                  {
                                                    key: `category_${i}`,
                                                    staticClass: "table_row",
                                                  },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "row_category_name",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "kt-form__group w-100",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "input-group",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "input-group-prepend",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ml-3 mr-4 pt-3",
                                                                        staticStyle:
                                                                          {
                                                                            cursor:
                                                                              "pointer",
                                                                          },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              $event.preventDefault()
                                                                              return _vm.removeGradeCategory(
                                                                                i
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "la la-times icon-sm",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "GradeTemplateColorSwatch",
                                                                      {
                                                                        staticClass:
                                                                          "swatch-input",
                                                                        attrs: {
                                                                          container:
                                                                            "gradeCategories",
                                                                          "color-change":
                                                                            _vm.selectCategoryColor,
                                                                          "color-selected":
                                                                            category.categoryColor,
                                                                          "item-index":
                                                                            i,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model.trim",
                                                                      value:
                                                                        _vm
                                                                          .gradeTemplate
                                                                          .categories[
                                                                          i
                                                                        ]
                                                                          .categoryName,
                                                                      expression:
                                                                        "gradeTemplate.categories[i].categoryName",
                                                                      modifiers:
                                                                        {
                                                                          trim: true,
                                                                        },
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control font",
                                                                  class:
                                                                    category.categoryColor,
                                                                  staticStyle: {
                                                                    "max-width":
                                                                      "200px",
                                                                  },
                                                                  attrs: {
                                                                    id: `categoryName${i}`,
                                                                    type: "text",
                                                                    placeholder:
                                                                      "e.g Homework",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm
                                                                        .gradeTemplate
                                                                        .categories[
                                                                        i
                                                                      ]
                                                                        .categoryName,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          _vm
                                                                            .gradeTemplate
                                                                            .categories[
                                                                            i
                                                                          ],
                                                                          "categoryName",
                                                                          $event.target.value.trim()
                                                                        )
                                                                      },
                                                                    blur: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$forceUpdate()
                                                                    },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "row_percent",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model.number",
                                                                value:
                                                                  _vm
                                                                    .gradeTemplate
                                                                    .categories[
                                                                    i
                                                                  ].percentage,
                                                                expression:
                                                                  "gradeTemplate.categories[i].percentage",
                                                                modifiers: {
                                                                  number: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control font",
                                                            class:
                                                              category.categoryColor,
                                                            staticStyle: {
                                                              width: "60px",
                                                            },
                                                            attrs: {
                                                              type: "text",
                                                              placeholder: "10",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm
                                                                  .gradeTemplate
                                                                  .categories[i]
                                                                  .percentage,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  _vm
                                                                    .gradeTemplate
                                                                    .categories[
                                                                    i
                                                                  ],
                                                                  "percentage",
                                                                  _vm._n(
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                )
                                                              },
                                                              blur: function (
                                                                $event
                                                              ) {
                                                                return _vm.$forceUpdate()
                                                              },
                                                            },
                                                          }),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass: "row_drop",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-switch kt-switch--icon kt-switch--sm mt-2",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .gradeTemplate
                                                                          .categories[
                                                                          i
                                                                        ]
                                                                          .dropLowest,
                                                                      expression:
                                                                        "gradeTemplate.categories[i].dropLowest",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                    name: `dropLowestGrade${i}`,
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        _vm
                                                                          .gradeTemplate
                                                                          .categories[
                                                                          i
                                                                        ]
                                                                          .dropLowest
                                                                      )
                                                                        ? _vm._i(
                                                                            _vm
                                                                              .gradeTemplate
                                                                              .categories[
                                                                              i
                                                                            ]
                                                                              .dropLowest,
                                                                            null
                                                                          ) > -1
                                                                        : _vm
                                                                            .gradeTemplate
                                                                            .categories[
                                                                            i
                                                                          ]
                                                                            .dropLowest,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            _vm
                                                                              .gradeTemplate
                                                                              .categories[
                                                                              i
                                                                            ]
                                                                              .dropLowest,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .gradeTemplate
                                                                                  .categories[
                                                                                  i
                                                                                ],
                                                                                "dropLowest",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .gradeTemplate
                                                                                  .categories[
                                                                                  i
                                                                                ],
                                                                                "dropLowest",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .gradeTemplate
                                                                              .categories[
                                                                              i
                                                                            ],
                                                                            "dropLowest",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _c("span"),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.selectedTab.tabName == "Gradebook Marks"
                              ? _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-12 col-lg-4" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "scaleType" } },
                                          [_vm._v("Mark Preset")]
                                        ),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.selectedMarkPreset,
                                                expression:
                                                  "selectedMarkPreset",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: { id: "markPresets" },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.selectedMarkPreset =
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                },
                                                _vm.applyMarkPreset,
                                              ],
                                            },
                                          },
                                          [
                                            _c("option", {
                                              attrs: { value: "" },
                                            }),
                                            _vm._l(
                                              Object.keys(
                                                _vm.validMarks
                                              ).sort(),
                                              function (scale) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: `vm_${scale}`,
                                                    domProps: { value: scale },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(scale) + " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6 col-lg-4" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-checkbox-inline mt-5 ml-4",
                                          },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "kt-checkbox" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.gradeTemplate
                                                          .allowAllNumerics,
                                                      expression:
                                                        "gradeTemplate.allowAllNumerics",
                                                    },
                                                  ],
                                                  attrs: {
                                                    id: "allowNumerics",
                                                    type: "checkbox",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.gradeTemplate
                                                        .allowAllNumerics
                                                    )
                                                      ? _vm._i(
                                                          _vm.gradeTemplate
                                                            .allowAllNumerics,
                                                          null
                                                        ) > -1
                                                      : _vm.gradeTemplate
                                                          .allowAllNumerics,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.gradeTemplate
                                                            .allowAllNumerics,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.gradeTemplate,
                                                              "allowAllNumerics",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.gradeTemplate,
                                                              "allowAllNumerics",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.gradeTemplate,
                                                          "allowAllNumerics",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(
                                                  " Allow Any Numeric Mark "
                                                ),
                                                _c("span"),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-6 col-lg-4" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-success btn-sm mt-4 pull-right",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.addAssignmentMark.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Add New Mark ")]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-clean btn-clean-danger btn-sm mt-4",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.clearMarks.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Clear Marks ")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "table-responsive" },
                                      [
                                        _c(
                                          "table",
                                          {
                                            ref: "marksTable",
                                            staticClass:
                                              "table table-hover table-borderless",
                                            attrs: { id: "validMarks" },
                                          },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c("th", [
                                                  _c("span", [
                                                    _vm._v("Gradebook "),
                                                    _c("br"),
                                                    _vm._v(" Mark"),
                                                  ]),
                                                ]),
                                                _c("th", [
                                                  _c("span", [
                                                    _vm._v("Numeric "),
                                                    _c("br"),
                                                    _vm._v(" Passthrough"),
                                                  ]),
                                                ]),
                                                _c("th", [
                                                  _c("span", [_vm._v(" ")]),
                                                ]),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.gradeTemplate.marks,
                                                function (mark, i) {
                                                  return _c(
                                                    "tr",
                                                    {
                                                      key: `mark_${i}`,
                                                      staticClass: "table_row",
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "row_desc",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "kt-form__group w-100 pt-3",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "input-group",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "input-group-prepend",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "ml-3 mr-4 pt-3",
                                                                          staticStyle:
                                                                            {
                                                                              cursor:
                                                                                "pointer",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.removeAssignmentMark(
                                                                                  i
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "la la-times icon-sm",
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "GradeTemplateColorSwatch",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "color-change":
                                                                                _vm.selectMarkColor,
                                                                              "color-selected":
                                                                                mark.gradeTemplateMarkColor,
                                                                              "item-index":
                                                                                i,
                                                                              container:
                                                                                "validMarks",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model.trim",
                                                                          value:
                                                                            mark.gradeTemplateMark,
                                                                          expression:
                                                                            "mark.gradeTemplateMark",
                                                                          modifiers:
                                                                            {
                                                                              trim: true,
                                                                            },
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control font",
                                                                    class:
                                                                      mark.gradeTemplateMarkColor,
                                                                    staticStyle:
                                                                      {
                                                                        "max-width":
                                                                          "96px",
                                                                      },
                                                                    attrs: {
                                                                      id: `mark_${i}`,
                                                                      maxlength:
                                                                        "3",
                                                                      type: "text",
                                                                      autocorrect:
                                                                        "off",
                                                                      autocomplete:
                                                                        "off",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        mark.gradeTemplateMark,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.$set(
                                                                            mark,
                                                                            "gradeTemplateMark",
                                                                            $event.target.value.trim()
                                                                          )
                                                                        },
                                                                      blur: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$forceUpdate()
                                                                      },
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "row_mark",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "kt-form__group w-100 pt-3",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "input-group",
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            mark.numericPassthrough,
                                                                          expression:
                                                                            "mark.numericPassthrough",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control font grade-props",
                                                                    class: [
                                                                      mark.numericPassthrough ||
                                                                      mark.numericPassthrough ===
                                                                        0
                                                                        ? "pass-through"
                                                                        : "excused",
                                                                      _vm
                                                                        .gradeTemplate
                                                                        .averageMethod ==
                                                                      "Points"
                                                                        ? "points"
                                                                        : "percentages",
                                                                      mark.gradeTemplateMarkColor ||
                                                                        "",
                                                                    ],
                                                                    attrs: {
                                                                      type: "number",
                                                                      placeholder:
                                                                        "Excused",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        mark.numericPassthrough,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.$set(
                                                                            mark,
                                                                            "numericPassthrough",
                                                                            $event
                                                                              .target
                                                                              .value
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                  mark.numericPassthrough ||
                                                                  mark.numericPassthrough ===
                                                                    0
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input-group-append",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "input-group-text",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .gradeTemplate
                                                                                      .averageMethod ==
                                                                                      "Points"
                                                                                      ? "Points"
                                                                                      : "Percent"
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "row_badge",
                                                        },
                                                        [
                                                          _vm.isExcusedMark(
                                                            mark
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "kt-form__group w-100 pt-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      class: `btn btn-clean Grey`,
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "  -  "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "kt-font-md pt-2 text-nowrap",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Excused "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm.isValidMark(
                                                                mark
                                                              )
                                                            ? _c("div", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    class: `btn btn-clean ${mark.gradeTemplateMarkColor}`,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          mark.gradeTemplateMark
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "kt-font-md pt-2 text-nowrap",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          mark.numericPassthrough
                                                                        ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .gradeTemplate
                                                                            .averageMethod ==
                                                                            "Points"
                                                                            ? "points"
                                                                            : "%"
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.selectedTab.tabName == "Averaging Scales"
                              ? _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-12 col-lg-4" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "scaleType" } },
                                          [_vm._v("Scale Type")]
                                        ),
                                        _c(
                                          "select",
                                          {
                                            staticClass: "form-control",
                                            attrs: { id: "scaleType" },
                                            domProps: {
                                              value:
                                                _vm.gradeTemplate.scale
                                                  .scaleName,
                                            },
                                            on: { input: _vm.scaleTypeChanged },
                                          },
                                          _vm._l(
                                            Object.keys(_vm.gradeScales).sort(),
                                            function (scale) {
                                              return _c(
                                                "option",
                                                {
                                                  key: _vm.gradeScales[scale]
                                                    .scaleId,
                                                  domProps: { value: scale },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(scale) + " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "table-responsive" },
                                    [
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "table table-hover table-borderless",
                                          attrs: { id: "gradeScales" },
                                        },
                                        [
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.gradeTemplate.scale.grades,
                                              function (value, i) {
                                                return _c(
                                                  "tr",
                                                  {
                                                    key: `row_${i}_${value.minGrade}`,
                                                    staticClass: "table_row",
                                                  },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "row_badge",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            class: `btn btn-clean ${value.color}`,
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  value.mark
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "kt-font-md pt-2 text-nowrap",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  value.minGrade
                                                                ) +
                                                                " - " +
                                                                _vm._s(
                                                                  value.maxGrade
                                                                ) +
                                                                _vm._s(
                                                                  _vm
                                                                    .gradeTemplate
                                                                    .averageMethod ===
                                                                    "Points"
                                                                    ? " Points"
                                                                    : "% Average"
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass: "row_desc",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "kt-form__group w-100",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "input-group pt-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "input-group-prepend",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "GradeTemplateColorSwatch",
                                                                      {
                                                                        staticClass:
                                                                          "swatch-input",
                                                                        attrs: {
                                                                          "color-change":
                                                                            _vm.selectScaleColor,
                                                                          "color-selected":
                                                                            value.color,
                                                                          "item-index":
                                                                            i,
                                                                          container:
                                                                            "gradeScales",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .gradeTemplate
                                                                          .scale
                                                                          .grades[
                                                                          i
                                                                        ]
                                                                          .displayName,
                                                                      expression:
                                                                        "gradeTemplate.scale.grades[i].displayName",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    id: `scaleValueName${i}`,
                                                                    type: "text",
                                                                    placeholder:
                                                                      "e.g Passing",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm
                                                                        .gradeTemplate
                                                                        .scale
                                                                        .grades[
                                                                        i
                                                                      ]
                                                                        .displayName,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          _vm
                                                                            .gradeTemplate
                                                                            .scale
                                                                            .grades[
                                                                            i
                                                                          ],
                                                                          "displayName",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c("td", {}, [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "kt-form__group w-100",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "btn-group pt-3",
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-clean btn-clean-color5",
                                                                  attrs: {
                                                                    type: "button",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        return _vm.lowerMaxGrade(
                                                                          i
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v(" - ")]
                                                              ),
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn btn-clean btn-clean-color5",
                                                                  attrs: {
                                                                    type: "button",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        return _vm.increaseMaxGrade(
                                                                          i
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [_vm._v(" + ")]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }