var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.itemIndex}_${_vm.container}` },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary dropdown-toggle",
          attrs: {
            id: `popover_${_vm.itemIndex}_${_vm.container}`,
            type: "button",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              _vm.popoverShow = true
            },
          },
        },
        [
          _c("span", { class: `colorSwatch ${_vm.colorSelected || ""}` }, [
            _vm._v(_vm._s(" ")),
          ]),
        ]
      ),
      _c(
        "b-popover",
        {
          ref: "popover",
          attrs: {
            target: `popover_${_vm.itemIndex}_${_vm.container}`,
            triggers: "focus",
            show: _vm.popoverShow,
            placement: "auto",
          },
          on: {
            "update:show": function ($event) {
              _vm.popoverShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [
              _c(
                "ul",
                { staticClass: "mx-1 px-1" },
                _vm._l(_vm.colorOptions.first, function (color) {
                  return _c(
                    "li",
                    {
                      key: `${color}_${_vm.itemIndex}_${_vm.container}`,
                      staticClass: "dropdown-item",
                      on: {
                        click: function ($event) {
                          _vm.colorChange(color, _vm.itemIndex)
                          _vm.popoverShow = false
                        },
                      },
                    },
                    [
                      _c("span", { class: `colorSwatch ${color}` }),
                      _c("span", [_vm._v(_vm._s(color))]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "col-6" }, [
              _c(
                "ul",
                { staticClass: "mx-1 px-1" },
                _vm._l(_vm.colorOptions.second, function (color) {
                  return _c(
                    "li",
                    {
                      key: `${color}_${_vm.itemIndex}_${_vm.container}`,
                      staticClass: "dropdown-item",
                      on: {
                        click: function ($event) {
                          _vm.colorChange(color, _vm.itemIndex)
                          _vm.popoverShow = false
                        },
                      },
                    },
                    [
                      _c("span", { class: `colorSwatch ${color}` }),
                      _c("span", [_vm._v(_vm._s(color))]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }