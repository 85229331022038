<template>
<div>
    <GeneralSubHeader v-if="isSetupWizard">
        <template #title>
            Grading Setup
        </template>
        <template #buttons />
    </GeneralSubHeader>
    <GeneralSubHeader v-else>
        <template #title>
            {{ gradeTemplate.gradeTemplateName }}
            <small class="ml-3">
                {{ isNew ? 'New' : 'Edit' }} Grade Template
            </small>
        </template>
        <template #buttons>
            <button
                :class="`btn btn-upper btn-primary ${saving ? 'kt-spinner kt-spinner--sm kt-spinner--light kt-spinner--left' : ''}`"
                @click.stop.prevent="save"
            >
                Save Template
            </button>
        </template>
    </GeneralSubHeader>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-12">
                <div class="kt-portlet">
                    <div v-if="isSetupWizard" class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <div v-if="!isAdminLocked && !finalized" style="width: 72px">
                                <span class="mt-3 kt-switch kt-switch--outline kt-switch--icon" :class="enableGrading ? 'kt-switch--success' : 'kt-switch--danger'">
                                    <label>
                                        <input
                                            v-model="enableGrading"
                                            type="checkbox"
                                        >
                                        <span />
                                    </label>
                                </span>
                            </div>
                            <h3 class="kt-portlet__head-title">
                                Default Grade Template
                                <small>
                                    {{ enableGrading ? 'Enabled' : 'Disabled' }}
                                </small>
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <button
                                v-if="enableGrading"
                                :class="`btn btn-primary ${saving ? 'kt-spinner kt-spinner--sm kt-spinner--light kt-spinner--left' : ''}`"
                                @click.stop.prevent="save"
                            >
                                Save Default School Template
                            </button>
                        </div>
                    </div>
                    <form class="kt-form kt-form--label-right p-4">
                        <b-overlay
                            :variant="'transparent'"
                            center
                            opacity="1"
                            blur="10px"
                            spinner="bubbles"
                            :show="!enableGrading"
                        >
                            <template #overlay>
                                <p />
                            </template>
                            <div class="kt-portlet__body">
                                <!-- Template Properties -->
                                <div class="form-group row">
                                    <div class="col-sm-8 col-md-6 col-lg-8">
                                        <label>Template Name</label>
                                        <input
                                            v-model="gradeTemplate.gradeTemplateName"
                                            type="text"
                                            class="form-control form-control-solid"
                                            placeholder="e.g. Math Template"
                                        >
                                    </div>
                                    <div class="col-sm-4 col-md-6 col-lg-4">
                                        <label for="averagingMethod">Averaging Method</label>
                                        <div class="kt-radio-inline mt-2">
                                            <label class="kt-radio kt-radio--brand">
                                                <input
                                                    v-model="gradeTemplate.averageMethod"
                                                    type="radio"
                                                    value="Points"
                                                    name="averagingMethod"
                                                >
                                                <span />
                                                Points
                                            </label>
                                            <label class="kt-radio kt-radio--brand">
                                                <input
                                                    v-model="gradeTemplate.averageMethod"
                                                    type="radio"
                                                    value="Percentages"
                                                    name="averagingMethod"
                                                >
                                                <span />
                                                Percentages
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-4">
                                        <div class="pr-4">
                                            <label for="maxPoints">Restrict Max Points</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span
                                                        v-if="maxPointsRestricted"
                                                        class="input-group-text"
                                                        @click.stop.prevent="gradeTemplate.maxPoints = null"
                                                    >
                                                        <label class="kt-checkbox kt-checkbox--single kt-checkbox--danger">
                                                            <input
                                                                type="checkbox"
                                                                checked="checked"
                                                            >
                                                            <span />
                                                        </label>
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="input-group-text"
                                                    >
                                                        <label class="kt-checkbox kt-checkbox--single kt-checkbox--disabled">
                                                            <input
                                                                type="checkbox"
                                                                disabled
                                                            >
                                                            <span />
                                                        </label>
                                                    </span>
                                                </div>
                                                <!-- https://stackoverflow.com/a/46039201 -->
                                                <input
                                                    id="maxPoints"
                                                    v-model="gradeTemplate.maxPoints"
                                                    type="number"
                                                    class="form-control form-control-solid"
                                                    min="0"
                                                    placeholder="Not Restricted"
                                                    @focusout="afterMaxPointsUpdated"
                                                    @input="numberChanged"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="pr-4">
                                            <label for="maxWeight">Restrict Weight</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span
                                                        v-if="maxWeightRestricted"
                                                        class="input-group-text"
                                                        @click.stop.prevent="gradeTemplate.maxWeight = null"
                                                    >
                                                        <label class="kt-checkbox kt-checkbox--single kt-checkbox--danger">
                                                            <input
                                                                type="checkbox"
                                                                checked="checked"
                                                            >
                                                            <span />
                                                        </label>
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="input-group-text"
                                                    >
                                                        <label class="kt-checkbox kt-checkbox--single kt-checkbox--disabled">
                                                            <input
                                                                type="checkbox"
                                                                disabled
                                                            >
                                                            <span />
                                                        </label>
                                                    </span>
                                                </div>
                                                <input
                                                    id="maxWeight"
                                                    v-model="gradeTemplate.maxWeight"
                                                    type="number"
                                                    class="form-control form-control-solid"
                                                    placeholder="Not Restricted"
                                                    min="0"
                                                    @focusout="afterMaxWeightUpdated"
                                                    @input="numberChanged"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="pr-4">
                                            <label for="floorAverages">Floor Averages</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span
                                                        v-if="floorAveragesRestricted"
                                                        class="input-group-text"
                                                        @click.stop.prevent="gradeTemplate.floorAverages = null"
                                                    >
                                                        <label class="kt-checkbox kt-checkbox--single kt-checkbox--danger">
                                                            <input
                                                                type="checkbox"
                                                                checked="checked"
                                                            >
                                                            <span />
                                                        </label>
                                                    </span>
                                                    <span
                                                        v-else
                                                        class="input-group-text"
                                                    >
                                                        <label class="kt-checkbox kt-checkbox--single kt-checkbox--disabled">
                                                            <input
                                                                type="checkbox"
                                                                disabled
                                                            >
                                                            <span />
                                                        </label>
                                                    </span>
                                                </div>
                                                <input
                                                    id="floorAverages"
                                                    v-model="gradeTemplate.floorAverages"
                                                    type="number"
                                                    class="form-control form-control-solid"
                                                    placeholder="Not Restricted"
                                                    min="0"
                                                    @focusout="afterFloorAveragesUpdated"
                                                    @input="numberChanged"
                                                >
                                                <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="">In School Scale Display Preferences:</label>
                                        <div class="kt-radio-list ml-3 mt-2">
                                            <label class="kt-radio kt-radio--solid">
                                                <input
                                                    v-model="gradeTemplate.schoolScaledDisplayPreference"
                                                    type="radio"
                                                    name="school-display"
                                                    :checked="gradeTemplate.schoolScaledDisplayPreference == 'ShowPercentage'"
                                                    value="ShowPercentage"
                                                > Category Raw Percentage
                                                <span />
                                            </label>
                                            <label class="kt-radio kt-radio--solid">
                                                <input
                                                    v-model="gradeTemplate.schoolScaledDisplayPreference"
                                                    type="radio"
                                                    name="school-display"
                                                    :checked="gradeTemplate.schoolScaledDisplayPreference == 'ShowScale'"
                                                    value="ShowScale"
                                                > Show Grade Scale
                                                <span />
                                            </label>
                                            <label class="kt-radio kt-radio--solid">
                                                <input
                                                    v-model="gradeTemplate.schoolScaledDisplayPreference"
                                                    type="radio"
                                                    name="school-display"
                                                    :checked="gradeTemplate.schoolScaledDisplayPreference == 'All'"
                                                    value="All"
                                                > Show All
                                                <span />
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="">Guardian/Student Scale Display Preferences:</label>
                                        <div class="kt-radio-list ml-3 mt-2">
                                            <label class="kt-radio kt-radio--solid">
                                                <input
                                                    v-model="gradeTemplate.homeScaledDisplayPreference"
                                                    type="radio"
                                                    name="home-display"
                                                    :checked="gradeTemplate.homeScaledDisplayPreference == 'ShowPercentage'"
                                                    value="ShowPercentage"
                                                > Category Raw Percentage
                                                <span />
                                            </label>
                                            <label class="kt-radio kt-radio--solid">
                                                <input
                                                    v-model="gradeTemplate.homeScaledDisplayPreference"
                                                    type="radio"
                                                    name="home-display"
                                                    :checked="gradeTemplate.homeScaledDisplayPreference == 'ShowScale'"
                                                    value="ShowScale"
                                                > Show Grade Scale
                                                <span />
                                            </label>
                                            <label class="kt-radio kt-radio--solid">
                                                <input
                                                    v-model="gradeTemplate.homeScaledDisplayPreference"
                                                    type="radio"
                                                    name="home-display"
                                                    :checked="gradeTemplate.homeScaledDisplayPreference == 'All'"
                                                    value="All"
                                                > Show All
                                                <span />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <!-- Sections -->
                                <ul
                                    class="nav nav-tabs nav-tabs-line  nav-tabs-line-brand"
                                    role="tablist"
                                >
                                    <li
                                        v-for="(tab, idx) in tabs"
                                        :key="`tab_${idx}`"
                                        class="nav-item"
                                    >
                                        <a
                                            class="nav-link"
                                            :class="{ active: tab.active }"
                                            data-toggle="tab"
                                            href="#"
                                            role="tab"
                                            @click.stop.prevent="selectTabByIndex(idx)"
                                        >
                                            <SVGIcon :name="tab.svgName" />
                                            {{ tab.tabName }}
                                        </a>
                                    </li>
                                </ul>

                                <div class="row">
                                    <!-- Grade Categories -->
                                    <div
                                        v-if="selectedTab.tabName == 'Grade Categories'"
                                        class="col-12"
                                    >
                                        <div class="form-group row">
                                            <div class="col-12">
                                                <button
                                                    class="btn btn-success btn-sm mt-4 pull-right"
                                                    @click.stop.prevent="addGradeCategory"
                                                >
                                                    Add New Category
                                                </button>
                                            </div>
                                        </div>

                                        <div class="table-responsive mb-0">
                                            <table
                                                id="gradingCategories"
                                                class="table table-hover table-borderless"
                                            >
                                                <thead class="kt-datatable__head kt-thead__light">
                                                    <tr class="kt-datatable__row">
                                                        <th>
                                                            <span>Category</span>
                                                        </th>
                                                        <th>
                                                            <span>Worth</span>
                                                        </th>
                                                        <th>
                                                            <span>Drop Lowest Grade</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(category, i) in gradeTemplate.categories"
                                                        :key="`category_${i}`"
                                                        class="table_row"
                                                    >
                                                        <td class="row_category_name">
                                                            <div class="kt-form__group w-100">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <div
                                                                            class="ml-3 mr-4 pt-3"
                                                                            style="cursor: pointer"
                                                                            @click.stop.prevent="removeGradeCategory(i)"
                                                                        >
                                                                            <i class="la la-times icon-sm " />
                                                                        </div>
                                                                        <GradeTemplateColorSwatch
                                                                            class="swatch-input"
                                                                            container="gradeCategories"
                                                                            :color-change="selectCategoryColor"
                                                                            :color-selected="category.categoryColor"
                                                                            :item-index="i"
                                                                        />
                                                                    </div>
                                                                    <input
                                                                        :id="`categoryName${i}`"
                                                                        v-model.trim="gradeTemplate.categories[i].categoryName"
                                                                        type="text"
                                                                        style="max-width: 200px"
                                                                        class="form-control font"
                                                                        :class="category.categoryColor"
                                                                        placeholder="e.g Homework"
                                                                    >
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="row_percent">
                                                            <div>
                                                                <input
                                                                    v-model.number="gradeTemplate.categories[i].percentage"
                                                                    type="text"
                                                                    style="width: 60px"
                                                                    class="form-control font"
                                                                    :class="category.categoryColor"
                                                                    placeholder="10"
                                                                >
                                                            </div>
                                                        </td>
                                                        <td class="row_drop">
                                                            <div>
                                                                <span class="kt-switch kt-switch--icon kt-switch--sm mt-2">
                                                                    <label>
                                                                        <input
                                                                            v-model="gradeTemplate.categories[i].dropLowest"
                                                                            type="checkbox"
                                                                            :name="`dropLowestGrade${i}`"
                                                                        >
                                                                        <span />
                                                                    </label>
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <!-- Valid Marks -->
                                    <div
                                        v-if="selectedTab.tabName == 'Gradebook Marks'"
                                        class="col-12"
                                    >
                                        <div class="form-group row">
                                            <div class="col-md-12 col-lg-4">
                                                <label for="scaleType">Mark Preset</label>
                                                <select
                                                    id="markPresets"
                                                    v-model="selectedMarkPreset"
                                                    class="form-control"
                                                    @change="applyMarkPreset"
                                                >
                                                    <option value="" />
                                                    <option
                                                        v-for="scale in Object.keys(validMarks).sort()"
                                                        :key="`vm_${scale}`"
                                                        :value="scale"
                                                    >
                                                        {{ scale }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 col-lg-4">
                                                <div class="kt-checkbox-inline mt-5 ml-4">
                                                    <label class="kt-checkbox">
                                                        <input
                                                            id="allowNumerics"
                                                            v-model="gradeTemplate.allowAllNumerics"
                                                            type="checkbox"
                                                        >
                                                        Allow Any Numeric Mark
                                                        <span />
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-4">
                                                <button
                                                    class="btn btn-success btn-sm mt-4 pull-right"
                                                    @click.stop.prevent="addAssignmentMark"
                                                >
                                                    Add New Mark
                                                </button>
                                                <button
                                                    class="btn btn-clean btn-clean-danger btn-sm mt-4"
                                                    @click.stop.prevent="clearMarks"
                                                >
                                                    Clear Marks
                                                </button>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <div class="table-responsive">
                                                <table
                                                    id="validMarks"
                                                    ref="marksTable"
                                                    class="table table-hover table-borderless"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <span>Gradebook <br> Mark</span>
                                                            </th>
                                                            <th>
                                                                <span>Numeric <br> Passthrough</span>
                                                            </th>
                                                            <th>
                                                                <span>&nbsp;</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            v-for="(mark, i) in gradeTemplate.marks"
                                                            :key="`mark_${i}`"
                                                            class="table_row"
                                                        >
                                                            <td class="row_desc">
                                                                <div class="kt-form__group w-100 pt-3">
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <div
                                                                                class="ml-3 mr-4 pt-3"
                                                                                style="cursor: pointer;"
                                                                                @click="removeAssignmentMark(i)"
                                                                            >
                                                                                <i class="la la-times icon-sm" />
                                                                            </div>
                                                                            <GradeTemplateColorSwatch
                                                                                :color-change="selectMarkColor"
                                                                                :color-selected="mark.gradeTemplateMarkColor"
                                                                                :item-index="i"
                                                                                container="validMarks"
                                                                            />
                                                                        </div>
                                                                        <input
                                                                            :id="`mark_${i}`"
                                                                            v-model.trim="mark.gradeTemplateMark"
                                                                            maxlength="3"
                                                                            type="text"
                                                                            autocorrect="off"
                                                                            style="max-width: 96px"
                                                                            class="form-control font"
                                                                            autocomplete="off"
                                                                            :class="mark.gradeTemplateMarkColor"
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="row_mark">
                                                                <div class="kt-form__group w-100 pt-3">
                                                                    <div class="input-group">
                                                                        <input
                                                                            v-model="mark.numericPassthrough"
                                                                            type="number"
                                                                            class="form-control font grade-props"
                                                                            :class="[
                                                                                mark.numericPassthrough || mark.numericPassthrough === 0 ? 'pass-through' : 'excused',
                                                                                gradeTemplate.averageMethod == 'Points' ? 'points' : 'percentages',
                                                                                mark.gradeTemplateMarkColor || '',
                                                                            ]"
                                                                            placeholder="Excused"
                                                                        >
                                                                        <div
                                                                            v-if="mark.numericPassthrough || mark.numericPassthrough === 0"
                                                                            class="input-group-append"
                                                                        >
                                                                            <span class="input-group-text">
                                                                                {{ gradeTemplate.averageMethod == 'Points' ? 'Points' : 'Percent' }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="row_badge">
                                                                <div
                                                                    v-if="isExcusedMark(mark)"
                                                                    class="kt-form__group w-100 pt-3"
                                                                >
                                                                    <div :class="`btn btn-clean Grey`">
                                                                        &nbsp;-&nbsp;
                                                                    </div>
                                                                    <div class="kt-font-md pt-2 text-nowrap">
                                                                        Excused
                                                                    </div>
                                                                </div>
                                                                <div v-else-if="isValidMark(mark)">
                                                                    <div :class="`btn btn-clean ${mark.gradeTemplateMarkColor}`">
                                                                        {{ mark.gradeTemplateMark }}
                                                                    </div>
                                                                    <div class="kt-font-md pt-2 text-nowrap">
                                                                        {{ mark.numericPassthrough }}
                                                                        {{ gradeTemplate.averageMethod == 'Points' ? 'points' : '%' }}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Grade Scales -->
                                    <div
                                        v-if="selectedTab.tabName == 'Averaging Scales'"
                                        class="col-12"
                                    >
                                        <div class="form-group row">
                                            <div class="col-md-12 col-lg-4">
                                                <label for="scaleType">Scale Type</label>
                                                <select
                                                    id="scaleType"
                                                    class="form-control"
                                                    :value="gradeTemplate.scale.scaleName"
                                                    @input="scaleTypeChanged"
                                                >
                                                    <option
                                                        v-for="scale in Object.keys(gradeScales).sort()"
                                                        :key="gradeScales[scale].scaleId"
                                                        :value="scale"
                                                    >
                                                        {{ scale }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="table-responsive">
                                            <table
                                                id="gradeScales"
                                                class="table table-hover table-borderless"
                                            >
                                                <tbody>
                                                    <tr
                                                        v-for="(value, i) in gradeTemplate.scale.grades"
                                                        :key="`row_${i}_${value.minGrade}`"
                                                        class="table_row"
                                                    >
                                                        <td class="row_badge">
                                                            <div :class="`btn btn-clean ${value.color}`">
                                                                {{ value.mark }}
                                                            </div>
                                                            <div class="kt-font-md pt-2 text-nowrap">
                                                                {{ value.minGrade }} - {{ value.maxGrade }}{{ gradeTemplate.averageMethod === 'Points' ? ' Points' : '% Average' }}
                                                            </div>
                                                        </td>
                                                        <td class="row_desc">
                                                            <div class="kt-form__group w-100">
                                                                <div class="input-group pt-3">
                                                                    <div class="input-group-prepend">
                                                                        <GradeTemplateColorSwatch
                                                                            class="swatch-input"
                                                                            :color-change="selectScaleColor"
                                                                            :color-selected="value.color"
                                                                            :item-index="i"
                                                                            container="gradeScales"
                                                                        />
                                                                    </div>
                                                                    <input
                                                                        :id="`scaleValueName${i}`"
                                                                        v-model="gradeTemplate.scale.grades[i].displayName"
                                                                        type="text"
                                                                        class="form-control"
                                                                        placeholder="e.g Passing"
                                                                    >
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="">
                                                            <div class="kt-form__group w-100">
                                                                <div class="btn-group pt-3">
                                                                    <button
                                                                        type="button"
                                                                        class="btn btn-clean btn-clean-color5"
                                                                        @click.stop.prevent="lowerMaxGrade(i)"
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        class="btn btn-clean btn-clean-color5"
                                                                        @click.stop.prevent="increaseMaxGrade(i)"
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-overlay>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import validate from 'validate.js';
import GradeTemplateColorSwatch from '../components/GradeTemplateColorSwatch.vue';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import Types from '../store/Types';
import * as network from '../network';

export default Vue.extend({
    name: 'GradeTemplateEditCreate',
    components: {
        GeneralSubHeader,
        GradeTemplateColorSwatch,
    },
    data() {
        return {
            selectedMarkPreset: null,
            saving: false,
            tabs: [{
                svgName: 'assignments',
                tabName: 'Grade Categories',
                active: true,
            }, {
                svgName: 'gradebook',
                tabName: 'Gradebook Marks',
                active: false,
            }, {
                svgName: 'averages',
                tabName: 'Averaging Scales',
                active: false,
            }],
            gradeTemplate: {
                gradeTemplateId: 0,
                gradeTemplateName: 'New Template',
                averageMethod: 'Points',
                schoolId: 0,
                userId: 0,
                schoolDefaultSchoolId: null,
                allowAllNumerics: true,
                maxPoints: null,
                maxWeight: null,
                floorAverages: null,
                schoolScaledDisplayPreference: 'All',
                homeScaledDisplayPreference: 'All',
                scale: {
                    scaleId: 0,
                    scaleName: '',
                    scaleDescription: '',
                    grades: [],
                },
                marks: [],
                categories: [],
            },
        };
    },
    computed: {
        isAdminLocked() {
            const school = this.$store.state.database.schools
                .find((s) => s.schoolId == this.$store.state.user.school.schoolId);
            const { schoolYear } = this.$store.state.user.school;
            const { isSuperAdmin } = this.$store.state.user;
            const { subscriptions } = school;
            const subscription = subscriptions.find((s) => s.schoolYear == schoolYear);
            let isAdminLocked = false;
            if (subscription) {
                isAdminLocked = subscription.adminLocked && !isSuperAdmin;
            }
            return isAdminLocked;
        },
        finalized() {
            return this.$store.state.user.school.finalized;
        },
        enableGrading: {
            get() {
                const { finalized } = this.$store.state.user.school;
                const { gradingEnabled } = this.$store.state.user.subscription;
                if (finalized || gradingEnabled) {
                    return gradingEnabled;
                } else {
                    return this.$store.state.wizard.bypassWizardGradingEnabled;
                }
            },
            set(enableGrading) {
                if (!this.isSetupWizard) return;
                const { finalized } = this.$store.state.user.school;
                if (finalized) return;
                this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, {
                    routeName: 'setup-grading',
                    value: enableGrading ? 'true' : '',
                });
                this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);
            },
        },
        gradeTemplates() {
            return this.$store.state.database.gradeTemplates;
        },
        selectedTab() {
            return this.tabs.find((t) => t.active);
        },
        isSetupWizard() {
            return this.$route.name === 'SetupWizardRoute';
        },
        template() {
            const { gradeTemplateId } = this.$route.params;

            if (this.isSetupWizard) { //returns the default template
                return this.gradeTemplates.find((t) => t.schoolDefaultSchoolId);
            }

            const template = this.gradeTemplates.find((t) => t.gradeTemplateId == gradeTemplateId) || null;
            if (template) template.categories = template.categories.filter((c) => !c.deleted);
            return template;
        },
        validMarks() {
            const { gradeScales, isNumber } = this;
            const { gradeTemplateId } = this.$route.params;
            const marks = {};
            let scaleName = '1-4';
            const gradeScale = gradeScales[scaleName];
            if (gradeScale) {
                // adds 1-4
                marks[scaleName] = { ...gradeScale };
                let markScale = marks[scaleName];
                const { grades } = markScale;
                markScale.grades = grades.map((g) => {
                    let numericPassthrough = null;
                    if (isNumber(g.mark)) numericPassthrough = parseInt(g.mark, 10);
                    return {
                        gradeTemplateId,
                        gradeTemplateMarkId: 0,
                        gradeTemplateMark: g.mark,
                        gradeTemplateMarkExplanation: g.displayName,
                        gradeTemplateMarkColor: g.color,
                        numericPassthrough,
                    };
                });

                // 1-4 with 10ths
                scaleName = '1-4 in tenths';
                marks[scaleName] = { ...gradeScale };
                markScale = marks[scaleName];

                markScale.grades = [];
                for (let mark = 0; mark < 4.1; mark += 0.1) {
                    const gradeTemplateMark = parseFloat(mark.toFixed(1));

                    let gradeTemplateMarkColor = 'Red';
                    if (mark > 3) gradeTemplateMarkColor = 'Blue';
                    else if (mark > 2) gradeTemplateMarkColor = 'Green';
                    else if (mark > 1) gradeTemplateMarkColor = 'Orange';
                    else if (mark == 1) gradeTemplateMarkColor = 'Red';

                    markScale.grades.push({
                        gradeTemplateId,
                        gradeTemplateMarkId: 0,
                        gradeTemplateMark: `${gradeTemplateMark}`,
                        gradeTemplateMarkExplanation: '',
                        gradeTemplateMarkColor,
                        numericPassthrough: gradeTemplateMark,
                    });
                }

                // 0-5
                scaleName = '0-5';
                marks[scaleName] = { ...gradeScale };
                markScale = marks[scaleName];

                markScale.grades = [];
                for (let mark = 0; mark < 5.1; mark += 1) {
                    const gradeTemplateMark = parseFloat(mark.toFixed(1));

                    let gradeTemplateMarkColor = 'Red';
                    if (mark > 4) gradeTemplateMarkColor = 'Blue';
                    else if (mark > 3) gradeTemplateMarkColor = 'Green';
                    else if (mark > 1) gradeTemplateMarkColor = 'Orange';

                    markScale.grades.push({
                        gradeTemplateId,
                        gradeTemplateMarkId: 0,
                        gradeTemplateMark: `${gradeTemplateMark}`,
                        gradeTemplateMarkExplanation: '',
                        gradeTemplateMarkColor,
                        numericPassthrough: gradeTemplateMark,
                    });
                }

                // 0-5 with 10ths
                scaleName = '0-5 in tenths';
                marks[scaleName] = { ...gradeScale };
                markScale = marks[scaleName];

                markScale.grades = [];
                for (let mark = 0; mark < 5; mark += 0.1) {
                    const gradeTemplateMark = parseFloat(mark.toFixed(1));

                    let gradeTemplateMarkColor = 'Red';
                    if (mark > 4) gradeTemplateMarkColor = 'Blue';
                    else if (mark > 3) gradeTemplateMarkColor = 'Green';
                    else if (mark > 2) gradeTemplateMarkColor = 'Orange';

                    markScale.grades.push({
                        gradeTemplateId,
                        gradeTemplateMarkId: 0,
                        gradeTemplateMark: `${gradeTemplateMark}`,
                        gradeTemplateMarkExplanation: '',
                        gradeTemplateMarkColor,
                        numericPassthrough: gradeTemplateMark,
                    });
                }

                // 0-10
                scaleName = '0-10';
                marks[scaleName] = { ...gradeScale };
                markScale = marks[scaleName];

                markScale.grades = [];
                for (let mark = 0; mark < 10.1; mark += 1) {
                    const gradeTemplateMark = parseFloat(mark.toFixed(1));

                    let gradeTemplateMarkColor = 'Red';
                    if (mark > 8) gradeTemplateMarkColor = 'Blue';
                    else if (mark > 6) gradeTemplateMarkColor = 'Green';
                    else if (mark > 4) gradeTemplateMarkColor = 'Orange';

                    markScale.grades.push({
                        gradeTemplateId,
                        gradeTemplateMarkId: 0,
                        gradeTemplateMark: `${gradeTemplateMark}`,
                        gradeTemplateMarkExplanation: '',
                        gradeTemplateMarkColor,
                        numericPassthrough: gradeTemplateMark,
                    });
                }

                // 0-10 with 10ths
                scaleName = '0-10 in tenths';
                marks[scaleName] = { ...gradeScale };
                markScale = marks[scaleName];

                markScale.grades = [];
                for (let mark = 0; mark < 10; mark += 0.1) {
                    const gradeTemplateMark = parseFloat(mark.toFixed(1));

                    let gradeTemplateMarkColor = 'Red';
                    if (mark > 8.9) gradeTemplateMarkColor = 'Blue';
                    else if (mark > 6.9) gradeTemplateMarkColor = 'Green';
                    else if (mark > 4.9) gradeTemplateMarkColor = 'Orange';

                    markScale.grades.push({
                        gradeTemplateId,
                        gradeTemplateMarkId: 0,
                        gradeTemplateMark: `${gradeTemplateMark}`,
                        gradeTemplateMarkExplanation: '',
                        gradeTemplateMarkColor,
                        numericPassthrough: gradeTemplateMark,
                    });
                }

                // adds 0 - 100
                scaleName = '0-100';
                marks[scaleName] = { ...gradeScale };
                markScale = marks[scaleName];
                markScale.grades = [];
                for (let mark = 0; mark <= 100; mark += 1) {
                    let gradeTemplateMarkColor = 'Red';
                    if (mark > 89) gradeTemplateMarkColor = 'Blue';
                    else if (mark > 69) gradeTemplateMarkColor = 'Green';
                    else if (mark > 60) gradeTemplateMarkColor = 'Orange';

                    markScale.grades.push({
                        gradeTemplateId,
                        gradeTemplateMarkId: 0,
                        gradeTemplateMark: `${mark}`,
                        gradeTemplateMarkExplanation: '',
                        gradeTemplateMarkColor,
                        numericPassthrough: mark,
                    });
                }

                // adds 0 - 100 in halves
                scaleName = '0-100 in halves';
                marks[scaleName] = { ...gradeScale };
                markScale = marks[scaleName];
                markScale.grades = [];
                for (let mark = 0; mark <= 100; mark += 0.5) {
                    const gradeTemplateMark = parseFloat(mark.toFixed(1));

                    let gradeTemplateMarkColor = 'Red';
                    if (mark > 89) gradeTemplateMarkColor = 'Blue';
                    else if (mark > 69) gradeTemplateMarkColor = 'Green';
                    else if (mark > 60) gradeTemplateMarkColor = 'Orange';

                    markScale.grades.push({
                        gradeTemplateId,
                        gradeTemplateMarkId: 0,
                        gradeTemplateMark: `${gradeTemplateMark}`,
                        gradeTemplateMarkExplanation: '',
                        gradeTemplateMarkColor,
                        numericPassthrough: gradeTemplateMark,
                    });
                }
            }
            return marks;
        },
        gradeScales() {
            const v = this;
            const output = {};
            const { gradeScales } = this.$store.state.database;
            const arraySortOrder = function (a, b) {
                if (a.minGrade < b.minGrade) return -1;
                if (a.minGrade > b.minGrade) return 1;
                return 0;
            };
            gradeScales.forEach((r) => {
                const grades = gradeScales
                    .filter((g) => g.scaleId == r.scaleId)
                    .map((x) => {
                        const row = x;
                        row.minGrade = parseInt(row.minGrade, 10);
                        row.maxGrade = parseInt(row.maxGrade, 10);
                        return row;
                    })
                    .sort(arraySortOrder);

                const key = r.scaleName;
                if (!output[key]) {
                    output[key] = {
                        scaleId: r.scaleId,
                        scaleDescription: r.description,
                        schoolDefaultSchoolId: v.isNew ? null : v.gradeTemplate.scale.schoolDefaultSchoolId || null,
                        scaleName: r.scaleName,
                        grades,
                    };
                }
            });

            return output;
        },
        maxPointsRestricted() {
            return this.gradeTemplate.maxPoints !== null;
        },
        floorAveragesRestricted() {
            return this.gradeTemplate.floorAverages !== null;
        },
        maxWeightRestricted() {
            return this.gradeTemplate.maxWeight !== null;
        },
        isNew() {
            if (this.isSetupWizard && this.template) {
                return false;
            }
            return !this.template;
        },
        deviceType() {
            return this.$store.state.deviceType;
        },
    },
    mounted() {
        const { isSetupWizard, isAdminLocked, enableGrading } = this;
        if (isSetupWizard && isAdminLocked && !enableGrading) {
            return this.$router.push({ name: 'SetupWizard' });
        }

        this.gradeTemplate.schoolId = this.$store.state.user.school.schoolId;
        this.gradeTemplate.userId = this.$store.state.user.userId;

        if (this.isNew) {
            this.gradeTemplate.categories.push({
                gradeTemplateId: 0,
                categoryColor: 'Green',
                gradeTemplateCategoryId: 0,
                dropLowest: false,
                percentage: 30,
                categoryName: 'Homework',
            });
            this.gradeTemplate.categories.push({
                gradeTemplateId: 0,
                categoryColor: 'Purple',
                gradeTemplateCategoryId: 0,
                dropLowest: false,
                percentage: 30,
                categoryName: 'Classwork',
            });
            this.gradeTemplate.categories.push({
                gradeTemplateId: 0,
                categoryColor: 'Orange',
                gradeTemplateCategoryId: 0,
                dropLowest: false,
                percentage: 40,
                categoryName: 'Assessments',
            });

            this.setScaleType('55-100 by 5s');
            return;
        }

        const template = { ...this.template };
        const { gradeTemplate } = this;
        gradeTemplate.gradeTemplateId = template.gradeTemplateId;
        gradeTemplate.gradeTemplateName = template.gradeTemplateName;
        gradeTemplate.schoolId = template.schoolId;
        gradeTemplate.averageMethod = template.averageMethod;
        gradeTemplate.schoolDefaultSchoolId = template.schoolDefaultSchoolId;
        gradeTemplate.allowAllNumerics = template.allowAllNumerics || false;
        gradeTemplate.maxPoints = template.maxPoints;
        gradeTemplate.maxWeight = template.maxWeight;
        gradeTemplate.floorAverages = template.floorAverages;
        gradeTemplate.schoolScaledDisplayPreference = template.schoolScaledDisplayPreference;
        gradeTemplate.homeScaledDisplayPreference = template.homeScaledDisplayPreference;
        gradeTemplate.scale.scaleId = template.scale.scaleId;
        gradeTemplate.scale.scaleName = template.scale.scaleName;
        gradeTemplate.scale.scaleDescription = template.scale.scaleDescription;
        gradeTemplate.scale.grades = template.scale.grades.map((x) => ({ ...x })).sort((a, b) => a.minGrade - b.minGrade);
        gradeTemplate.marks = template.marks.map((x) => ({ ...x }));
        gradeTemplate.categories = template.categories.map((x) => ({ ...x })).filter((c) => !c.deleted);
    },
    methods: {
        isExcusedMark(mark) {
            return mark.numericPassthrough === '' || mark.numericPassthrough == null;
        },
        isValidMark(mark) {
            const unset = mark.numericPassthrough === '' || mark.numericPassthrough == null;
            if (unset) return false;
            return this.isNumber(mark.numericPassthrough);
        },
        selectTabByIndex(idx) {
            const { tabs } = this;
            this.tabs = tabs.map((t, i) => {
                const tab = t;
                tab.active = (i == idx);
                return tab;
            });
        },
        applyMarkPreset() {
            const { selectedMarkPreset, validMarks, gradeTemplate } = this;
            if (!selectedMarkPreset) return;
            gradeTemplate.marks = [];

            const scale = validMarks[selectedMarkPreset];
            scale.grades.forEach((g) => {
                gradeTemplate.marks.push({
                    gradeTemplateMark: g.gradeTemplateMark,
                    gradeTemplateMarkExplanation: g.gradeTemplateMarkExplanation,
                    gradeTemplateMarkColor: g.gradeTemplateMarkColor,
                    numericPassthrough: g.numericPassthrough,
                });
            });

            this.selectedMarkPreset = null;
        },
        clearMarks() {
            this.gradeTemplate.marks = [];
        },
        addAssignmentMark() {
            this.gradeTemplate.marks.unshift({
                gradeTemplateMark: 'EX',
                gradeTemplateMarkExplanation: 'Excused',
                gradeTemplateMarkColor: 'Red',
                numericPassthrough: null,
            });
        },
        isNumber(n) {
            const validNumberError = validate.single(n, {
                numericality: {
                    presence: false,
                    greaterThanOrEqualTo: 0,
                },
            });
            return !validNumberError;
        },
        removeAssignmentMark(removeIndex) {
            this.gradeTemplate.marks = this.gradeTemplate.marks.filter((a, i) => i !== removeIndex);
        },
        removeGradeCategory(removeIndex) {
            this.gradeTemplate.categories = this.gradeTemplate.categories.filter((category, i) => i !== removeIndex);
        },
        selectCategoryColor(color, i) {
            this.gradeTemplate.categories[i].categoryColor = color;
        },
        selectMarkColor(color, i) {
            this.gradeTemplate.marks[i].gradeTemplateMarkColor = color;
        },
        selectScaleColor(color, i) {
            this.gradeTemplate.scale.grades[i].color = color;
        },
        lowerMaxGrade(i) {
            // don't go below zero & never let it go beyond min
            const greaterThanZero = this.gradeTemplate.scale.grades[i].maxGrade > 0;
            const lowerValueIsValid = this.gradeTemplate.scale.grades[i].minGrade < this.gradeTemplate.scale.grades[i].maxGrade;

            if (greaterThanZero && lowerValueIsValid) {
                this.gradeTemplate.scale.grades[i].maxGrade -= 1;

                if (this.gradeTemplate.scale.grades[i + 1]) {
                    this.gradeTemplate.scale.grades[i + 1].minGrade -= 1;
                }
            }
        },
        increaseMaxGrade(i) {
            // don't go above 100 & never let it go beyond next level's min
            const lessThanZero = this.gradeTemplate.scale.grades[i].maxGrade < 100;
            const higherValueIsValid = !this.gradeTemplate.scale.grades[i + 1] || this.gradeTemplate.scale.grades[i].maxGrade < this.gradeTemplate.scale.grades[i + 1].maxGrade - 1;

            if (lessThanZero && higherValueIsValid) {
                this.gradeTemplate.scale.grades[i].maxGrade += 1;
                if (this.gradeTemplate.scale.grades[i + 1]) {
                    this.gradeTemplate.scale.grades[i + 1].minGrade += 1;
                }
            }
        },
        addGradeCategory() {
            this.gradeTemplate.categories.push({
                latePercentDock: '0',
                gradeTemplateId: this.gradeTemplate.gradeTemplateId,
                categoryColor: 'Red',
                gradeTemplateCategoryId: 0,
                dropLowest: false,
                percentage: 30,
                categoryName: 'New Category',
            });
        },
        scaleTypeChanged(e) {
            const scaleType = e.target.value;
            this.setScaleType(scaleType);
        },
        setScaleType(scaleType) {
            const v = this;
            const dbScales = v.gradeScales[scaleType];
            if (!dbScales) return;

            this.gradeTemplate.scale = {
                scaleId: dbScales.scaleId,
                scaleDescription: dbScales.scaleDescription,
                schoolDefaultSchoolId: this.gradeTemplate.gradeTemplateId == 0 ? null : this.gradeTemplate.schoolDefaultSchoolId || null,
                scaleName: dbScales.scaleName,
                grades: dbScales.grades,
            };
        },
        numberChanged(e) {
            const { value } = e.target;
            e.target.value = !!value && Math.abs(value) >= 0 ? Math.abs(value) : null;
        },
        afterMaxWeightUpdated() {
            const { maxWeight } = this.gradeTemplate;
            if (maxWeight == 0 || maxWeight == null) {
                this.gradeTemplate.maxWeight = null;
            }
        },
        afterFloorAveragesUpdated() {
            const { floorAverages } = this.gradeTemplate;
            if (floorAverages == 0 || floorAverages == null) {
                this.gradeTemplate.floorAverages = null;
            }
        },
        afterMaxPointsUpdated() {
            const { maxPoints } = this.gradeTemplate;
            if (maxPoints == 0 || maxPoints == null) {
                this.gradeTemplate.maxPoints = null;
            }
        },
        save() {
            const v = this;
            const { gradeTemplate, showError } = this;
            if (!gradeTemplate.gradeTemplateName) return 'Please enter a template name';
            if (gradeTemplate.categories.length == 0) return 'Please enter atleast one grade category';
            if (this.categoryWeightsValid) return 'Category weights must add up to 100';

            if (document.querySelector('#validMarks .is-invalid')) {
                return showError('Correct the errors in red and try again');
            }

            if (v.saving) return;
            v.saving = true;

            const { schoolId, schoolTermId } = this.$store.state.user.school;
            const callback = (err) => {
                v.saving = false;
                if (err) return showError(err);
                v.$store.dispatch(Types.actions.RELOAD_GRADE_TEMPLATES);
                v.$router.push({ name: 'GradeTemplateList' });
            };

            const { gradeTemplateId } = gradeTemplate;
            gradeTemplate.marks.map((m) => {
                const mark = m;
                if (mark.numericPassthrough === '') mark.numericPassthrough = null;
                return mark;
            });

            if (gradeTemplateId) {
                const params = {
                    url: { schoolId, schoolTermId, gradeTemplateId },
                    body: { gradeTemplate },
                };
                return network.gradeTemplates.editGradeTemplate(params, callback);
            }

            const params = {
                url: { schoolId, schoolTermId },
                body: { gradeTemplate },
            };
            network.gradeTemplates.createGradeTemplate(params, callback);
        },
    },
});

</script>

<style>
div.swatch-input button {
    height: 38px !important;
}
</style>

<style scoped>
    #gradingCategories.table {
        width: auto;
    }
    #gradingCategories td.row_category_name {
        min-width: 260px;
        max-width: 260px;
    }

    #gradeScales.table {
        width: auto;
    }

    #validMarks.table {
        width: auto;
    }

    #validMarks td.row_desc {
        min-width: 190px;
        max-width: 190px;
    }
    #validMarks td.row_mark {
        min-width: 180px;
        max-width: 180px;
    }
    #validMarks td.row_badge {
        min-width: 120px;
        max-width: 120px;
    }
    input.grade-props {

    }
    input.pass-through {
        width: 80px !important;
        max-width: 80px !important;
    }
    input.grade-props.excused {

    }

    input.grade-props.points {
        width: 148px;
        max-width: 148px;
    }

    input.grade-props.percentages {
        width: 160px;
        max-width: 160px;
    }

    #gradingCategories tr.table_row td > div {
        margin-top: 10px;
    }
    #validMarks tr.table_row td > div {
        margin-top: 0px;
        margin-bottom: 10px;
    }
    #gradeScales tr.table_row td > div {
        margin-top: 10px;
    }

    .kt-checkbox--disabled {
        pointer-events: none;
    }

    div.number-group {
        padding-left: 10px;
        padding-right: 10px;
    }

    td.row_category_name {
        min-width: 240px;
    }
    td.row_badge {
        text-align:center;
    }
    td.row_badge div.btn-clean {
        /* width: 50px; */
        pointer-events: none;
        font-size: 1rem;
    }
</style>
