<template>
<div :key="`key_${itemIndex}_${container}`">
    <button
        :id="`popover_${itemIndex}_${container}`"
        class="btn btn-secondary dropdown-toggle"
        type="button"
        @click.stop.prevent="popoverShow = true"
    >
        <span :class="`colorSwatch ${colorSelected || ''}`">{{ " " }}</span>
    </button>
    <b-popover
        ref="popover"
        :target="`popover_${itemIndex}_${container}`"
        triggers="focus"
        :show.sync="popoverShow"
        placement="auto"
    >
        <div class="row">
            <div class="col-6">
                <ul class="mx-1 px-1">
                    <li
                        v-for="color in colorOptions.first"
                        :key="`${color}_${itemIndex}_${container}`"
                        class="dropdown-item"
                        @click="colorChange(color, itemIndex); popoverShow = false"
                    >
                        <span :class="`colorSwatch ${color}`" />
                        <span>{{ color }}</span>
                    </li>
                </ul>
            </div>
            <div class="col-6">
                <ul class="mx-1 px-1">
                    <li
                        v-for="color in colorOptions.second"
                        :key="`${color}_${itemIndex}_${container}`"
                        class="dropdown-item"
                        @click="colorChange(color, itemIndex); popoverShow = false"
                    >
                        <span :class="`colorSwatch ${color}`" />
                        <span>{{ color }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </b-popover>
</div>
</template>

<script>
export default {
    name: 'GradeTemplateColorSwatch',
    props: {
        colorChange: {
            type: Function,
            required: true,
        },
        colorSelected: {
            type: String,
            required: true,
        },
        itemIndex: {
            type: Number,
            required: true,
        },
        container: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            popoverShow: false,
            colorOptions: {
                first: [
                    'Red',
                    'Orange',
                    'Yellow',
                    'Green',
                ],
                second: [
                    'Blue',
                    'Purple',
                    'Brown',
                ],
            },

        };
    },
    methods: {
        onClose() {
            this.popoverShow = false;
        },
    },
};
</script>

<style scoped>
.color-menu {
    width: 400px;
}
.dropdown-item {
    cursor: pointer;
}
</style>
